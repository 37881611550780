import React from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout, reset } from '../reduxAuth/authSlice';

function AccessIsDenied() {
  
  const navigate             = useNavigate();
    const dispatch           = useDispatch();

    const onLogOut = () => {

      dispatch(logout());
      dispatch(reset());
      navigate("/login");
    }

  return (
    <div>
      <div className="container">
       <h3> Access Denied</h3>
      <p>Your account or pharmacy may be disabled, please contact your rescure scripts administrator to get it sorted.</p>
            <button className="btn btn-danger btn-full" onClick={onLogOut}>Logout</button>
      </div>
    </div>
  )
}

export default AccessIsDenied