import React from 'react'
import { Link } from 'react-router-dom'
import "./home.css";

function Home() {
  return (
    <div className="body-wrap boxed-container home-outer">
          <div className="header-strip">
         <Link to={"/login"} className="btn btn-orangish">Login</Link>
         </div>
         <div className="main-home">
             <div className="content">
             <div className="mgtop60">
                 <h1>Welcome to CJMEDILUS</h1>
                  <p>CJMEDILUS is the trusted platform of CJ Marketing, specializing in providing accurate and up-to-date scheduled medicine data. Our primary focus is on maintaining a comprehensive and reliable database, ensuring that healthcare professionals, pharmacies, and patients have access to vital information on scheduled medications.</p>  
                  <h3>Mission</h3>
                    <p>Our mission at CJMEDILUS is to streamline the process of accessing scheduled medicine data, promoting informed decision-making, and enhancing patient care.
                    </p>
                    <div className="bold">What We Do</div>
                    <ul>
                      <li>Aggregate and verify scheduled medicine data from reputable sources</li>
                      <li>Maintain a user-friendly database for easy searching and reference</li>
                      <li>Provide timely updates on changes to scheduled medicine listings</li>    
                      <li>Support healthcare professionals, pharmacies, and patients with accurate information</li>
                    </ul>
                      <div className="bold">Contact</div>
                      <p>
                      For more information or inquiries about CJMEDILUS and our services, please visit our website or reach out to us directly.
                      </p>
              </div>

              <div className="cicle-relative-group">
                     <div className="shape-circle size-circle1 circle-redish shape-posi1"></div>
                     <div className="shape-circle size-circle2 circle-blueish shape-posi2"></div>
                     <div className="shape-circle size-circle3 circle-redish shape-posi3"></div>
                     <div className="shape-circle size-circle4 circle-blum shape-posi4"></div>
                </div>
             </div>
         </div>
    </div>
  )
}

export default Home