import React, { useEffect, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import "./authlog.css";
import axios from 'axios';
import * as CONSTANTS from "../CONSTANTS";
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Spinner from '../Components/Others/Spinner';
import { login, reset } from '../reduxAuth/authSlice';
import logoHeard from '../assets/logo_medical.png';

function AuthLogin() {
  const emailRef                                        = useRef();
  const passwordRef                                     = useRef();

  const navigate                                        = useNavigate();
  const dispatch                                        = useDispatch();

  const {user, isLoading, isError, isSuccess, message}  = useSelector((state) => state.auth);

  useEffect(() => {
      if(isError){
        toast.error(message)
      }

      if(isSuccess || user){
          navigate('/medicines');
        }

        dispatch(reset())
    },[user, isError, isSuccess, message, navigate, dispatch])

    const handleLogin = async (e) => {
      e.preventDefault();

          let emailCheckTest = isValidEmail(emailRef.current.value);
          if(emailCheckTest){
              const userData = {
                  "email": emailRef.current.value,
                  "password": passwordRef.current.value
                }        
                
                dispatch(login(userData));
          }else {
              toast.error("Email not valid");
          }
      }

      function isValidEmail(email){
        return /\S+@\S+\.\S+/.test(email);
    }

    if (isLoading) {
        return  <Spinner />
    }


  return (
    <div className="body-log">
          <div class="log-container">
               <div className="image-login-head">
                  <img src={logoHeard} className="img-logo" />
               </div>
              <div class="signin-form">
                  <h2>Sign In</h2>
                  <form encType="multipart/form-data">
                      <div class="input-group">
                          <label for="username">Email:</label>
                          <input type="text" className="form-control txt-log"  ref={emailRef} required/>
                      </div>
                      <div class="input-group">
                          <label for="password">Password:</label>
                          <input type="password" className="form-control txt-log" ref={passwordRef} required/>
                      </div>                     
                      
                      <button className="btn btn-full button-log" onClick={handleLogin}  disabled={isLoading} >Login</button>
                  </form>
              </div>
          </div>
    </div>
  )
}

export default AuthLogin