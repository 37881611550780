
export const API_URL = "https://cjmedilus-47c38d92aa3d.herokuapp.com/api/";
export const SESSION_COOKIE = "userBlackMampatileforTHeRiskWeMedilUsCJ";

/*
////////////////////////////////////////////////////////////////
export const API_URL = "http://localhost:1885/api/";
export const SESSION_COOKIE = "userBlackMampaLocalskWeMedilUsCJ";
////////////////////////////////////////////////////////////////
*/

export const SMALL_PROCESSING = "https://cjmarketing.co/image_directory/loader-blue.gif";
export const IMAGE_PROFILE = "https://newclient.cjdsa.co.za/images/base/profile.jpg";
