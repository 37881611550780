import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';
import axios from 'axios';
import * as CONSTANTS from "../CONSTANTS";
import { useSelector } from 'react-redux';

function MedicineUpload() {
  const {user}                                                       = useSelector((state) => state.auth);
  const mainExcelRef                                                 = useRef();

  const [processing, setProcessing]                                  = useState(false);
  const [columnList, setColumnList]                                  = useState();
  const [dataJsonUnEdited, setDataJsonUnEdited]                      = useState();
  const [countAmt, setCountAmt]                                      = useState(0);
  const [log, setLog]                                                = useState([]);
  const [transfersCount, setTransferCount]                           = useState(0);

  //Napi Code - 3
  //Schedule - 5
  //Property Name - 6
  //Active Ingredients - 7
  //Strength - 8 
  //Dosage - 9
  //Unit - 10
  //Pack Size - 11
  //Qty - 12 
  const colIndex = [3, 5, 6, 7 , 8, 9, 10, 11, 12];

  async function handleFromUploadExcelSheet(e) {
    e.preventDefault();

    if(mainExcelRef.current.files[0].length !== 0){
      const excelData = mainExcelRef.current.files[0];
      const data = await excelData.arrayBuffer();
      
      //Worksheet
      var workbook = XLSX.read(data);
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const dataJCol = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      const dataJson = XLSX.utils.sheet_to_json(worksheet, { defval: "", raw: true });
      // Extract column names (first row of the JSON data)
      const columnNames = dataJCol[0];

      //console.log(columnNames);
      console.log(dataJson);
      setColumnList(columnNames);
      setDataJsonUnEdited(dataJson);
      setCountAmt(dataJson.length);
    }else {
      toast.error("File is empty, can't upload.");
    }
  }

  const processSendingData = async () => {
    try{
      setProcessing(true);
      //countAmt
      //console.log(countAmt)
        for (let i = 1; i < countAmt; i++) {

          const row = dataJsonUnEdited[i];
       
          if(row[columnList[colIndex[0]]] !== ""){
            
            const medicineProduct = {
              "proprietaryName" : row[columnList[6]],
              "applicant": {
                "name": row[columnList[1]],
                "licenseNo": row[columnList[0]].toString()
              },
              "nappiCode" : row[columnList[3]],
              "schedule" : row[columnList[5]],
              "activeIngredient": row[columnList[7]],
              "strength": row[columnList[8]],
              "dosage": row[columnList[9]],
              "unit": row[columnList[10]],
              "packSize": row[columnList[11]],
              "quantity": row[columnList[12]],
            }

           // console.log(medicineProduct);
           // console.log("Jooooaaa");
            try{
                
                const res = await axios.post(CONSTANTS.API_URL +"medicine/creation/", medicineProduct ,{
                      headers: {
                          token: "Bearer "+ user.accessToken
                      }
                  });
                
              //console.log(res);
              setLog((prevLog) => [...prevLog, `Row ${i + 1}): ${res.data.proprietaryName} - Successful.`]);
              
              setTransferCount(transfersCount + 1);
            }catch(err){
              console.log(err);
              console.log("Doe");
              setLog((prevLog) => [...prevLog, `Row ${i + 1}: Error - ${row[columnList[6]]} - Unsuccessful.`]);
            }
          }
               
      }

      toast.success("Successfully added records ("+ transfersCount + ").");
      setProcessing(false);
    }catch(err){
      setProcessing(false);
      console.log(err);
    }
  }

  return (
    <div className="box-page">
      <h3>Medicine Upload</h3>
      <Link className="btn btn-info" to="/medicines">View Medicine</Link>
      <div className="box-body">
      <div className="row">
        <div className="col-md-5">
          <form onSubmit={handleFromUploadExcelSheet} encType="multipart/form-data">
              <div className="form-group">
                <input type="file" ref={mainExcelRef} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" className="form-control" required/>
              </div>
              <div className="form-group button-row">
                <button className="btn btn-danger" disabled={processing}>Import</button>
              </div>
            </form>
        </div>
        <div className="col-md-7">
          <div className="faze-display">
              <div className="flexme info-attach">
                  <div className="info-a"><div className="sbox"></div> <span>Fixed Columns</span></div>
                  <div className="info-a"><div className="sbox color-gray1"></div> <span>Excel Columns</span></div>
              </div>
              {
                columnList && (
                  <div className="box-feedback">
                    <table className="table table-border">
                      <tbody>
                        <tr>
                          <td>Nappi Code</td>
                          <td>Schedule</td>
                          <td>Name</td>                        
                          <td>Active Ingredients</td>
                          <td>Strength</td>
                          <td>Unit</td>
                          <td>Dosage</td>
                          <td>P Size</td>
                          <td>Qty</td>
                        </tr>
                        <tr className="answers-row">
                          <td>{columnList[colIndex[0]]}</td>
                          <td>{columnList[colIndex[1]]}</td>
                          <td>{columnList[colIndex[2]]}</td>
                          <td>{columnList[colIndex[3]]}</td>
                          <td>{columnList[colIndex[4]]}</td>
                          <td>{columnList[colIndex[5]]}</td>
                          <td>{columnList[colIndex[6]]}</td>
                          <td>{columnList[colIndex[7]]}</td>
                          <td>{columnList[colIndex[8]]}</td>
                        </tr>
                      </tbody>
                    </table>

                    <div className="mgtop30">
                      <button className="btn btn-primary" onClick={() => processSendingData()} disabled={processing}>Upload</button>
                    </div>
                  </div>
                )
              }
          </div>
        </div>
      </div>
      <div className="row">
          {
            log && (
              <div className="log-box">
                 {
                    log.map((entry, index) => (
                      <p key={index} className="calm-boarder">{entry}</p>
                    ))
                }
              </div>
            )
          }
      </div>
       
       {
            processing && (
              <div className="processes-container">
                  <img src={CONSTANTS.SMALL_PROCESSING} className="img-process" />
              </div>
            )
       }
    </div>
    </div>
  )
}

export default MedicineUpload