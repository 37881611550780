import React from 'react';
import { Link } from 'react-router-dom';
import "./sidenavigate.css";
import logoSide from "../../assets/logo_medical.png";
import { FaHome, FaSearch, FaUpload } from 'react-icons/fa';
import { logout } from '../../reduxAuth/authSlice';
import { useDispatch } from 'react-redux';
import * as CONSTANTS from "../../CONSTANTS";

function SideBarNavigation({members}) {

  const dispatch                                                           = useDispatch();

  const handleLogout = () => {
    localStorage.removeItem(CONSTANTS.SESSION_COOKIE);
    dispatch(logout());
    document.location.href("/");
  }

  return (
    <div className="navbar navbar-vertical navbar-expand-lg">
      <div className="side-stream">
        <div className="navbar-vertical">
          <ul className="navbar-nav flex-column">
            <li className="nav-item">
              <div className="nav-item-wrapper nav-button text-center">
                <img src={logoSide} className="dashimage" />
              </div>
            </li>
            <li className="nav-item">
              <div className="nav-item-wrapper nav-button text-center">
                 <div className="regime-logout">
                    <button className="btn btn-loggy" onClick={handleLogout}>Log Out</button>
                 </div>
              </div>
            </li>
            <li className="nav-item">
              <div className="nav-item-wrapper">
                <Link to="/start" className="link-nav-title">
                  <span className="icon-side"><FaHome /></span> <span className="icon-text">Summary</span>
                </Link>
              </div>
            </li>
            <li className="nav-item">
              <div className="nav-item-wrapper">
                <Link to="/medicines" className="link-nav-title">
                  <span className="icon-side"><FaHome /></span> <span className="icon-text">Medicine</span>
                </Link>
              </div>
            </li>
            <li className="nav-item">
              <div className="nav-item-wrapper">
                   <Link to="/medicine-upload" className="link-nav-title">
                      <span className="icon-side"><FaUpload /></span> <span className="icon-text">Medicine Upload </span>
                    </Link>
              </div>
            </li>
            <li className="nav-item">
              <div className="nav-item-wrapper">
                   <Link to="/medicine-search" className="link-nav-title">
                      <span className="icon-side"><FaSearch /></span> <span className="icon-text">Medicine Search</span>
                  </Link>
              </div>
            </li>
          </ul>
        </div>
      </div>     
    </div>
  )
}

export default SideBarNavigation