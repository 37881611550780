import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import SidebarNavigation from '../Components/Navigation/SideBarNavigation';
import AccessIsDenied from './AccessIsDenied';

function Dashboard() {
  const navigate              = useNavigate();
  const {user}                = useSelector((state) => state.auth)

  useEffect(() => {

      if(!user){
          navigate("/login");
      }
  },[user, navigate])

  return (
    <div className="dashingboard-outer">   
    {
    user &&  (user.approve != false)  ? (
                <div className="dashingboard">
                        <div className="side-dash">
                              <SidebarNavigation member={user} />
                          </div>
                        
                          <div className="main-dash-content">
                                  
                                  <div className="container">
                                      <Outlet />
                                  </div>
                          </div>
                      </div>
                    )
            :
              <AccessIsDenied />
            }       
 </div>
  )
}

export default Dashboard